import Image from "@components/shared/sub/image";
import { Maybe, SanityImageCard } from "@graphql-types";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import React from "react";
import styled from "styled-components";
import { GridItem } from "./PricingList";

const ImageGridItem = styled(GridItem)<{ gridRow: number; gridColumn: number }>`
  @media (max-width: ${TABLET_BREAKPOINT}px) and (min-width: ${MOBILE_BREAKPOINT +
    1}px) {
    grid-column: ${({ gridColumn }) => ((gridColumn - 1) % 2) + 1};
    grid-row: ${({ gridRow, gridColumn }) =>
      Math.floor((gridColumn - 1) / 2) * 3 + gridRow};
    gap: 5px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-column: 1;
    grid-row: ${({ gridRow, gridColumn }) =>
      Math.floor(gridColumn) * 3 + gridRow};
  }
`;

interface ImageItemProps {
  column: number;
  imageItem: Maybe<SanityImageCard>;
  assetLoading?: "lazy" | "eager";
}

const ImageItem = ({ column, imageItem, assetLoading }: ImageItemProps) => {
  if (!imageItem) return <p>this image item could not be retrieved!</p>;

  return (
    <>
      <ImageGridItem
        gridRow={1}
        gridColumn={column}
        style={{ justifyContent: "center" }}
      >
        <Image
          data={imageItem.image!}
          width="200px"
          height="200px"
          loading={assetLoading}
        />
      </ImageGridItem>
      <ImageGridItem gridRow={2} gridColumn={column}>
        <h3 style={{ textAlign: "center", marginBottom: 0 }}>
          {imageItem.title}
        </h3>
      </ImageGridItem>
      <ImageGridItem gridRow={3} gridColumn={column}>
        <p style={{ fontSize: "12pt", textAlign: "center" }}>
          {imageItem.description}
        </p>
      </ImageGridItem>
    </>
  );
};

export { ImageItem };
export type { ImageItemProps };
