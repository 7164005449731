import { useCustomerLogosQuery } from "@api";
import { GridList } from "@components-v3/GridList";
import LayOut from "@components/layout";
import CustomersBlock from "@components/shared/customersBlock";
import FeaturedBlog from "@components/shared/featuredBlogPost";
import Hero from "@components/shared/hero";
import SEO from "@components/shared/seo";
import TestimonialsSlider from "@components/shared/slider/testimonialsSlider";
import SharedCarousel from "@components/shared/sub/carousel";
import { Query, SanityCustomerLogos, SanityCustomersPageConnection } from "@graphql-types";
import { useStore } from "@state/store";
import { isNotNullOrUndefined } from "@util/assertions";
import { useRegionChanged } from "@util/hooks";
import { Container } from "@util/standard";
import { PageProps, graphql } from "gatsby";
import React, { useEffect, useState } from "react";

interface Data extends Query {
  altRegions: SanityCustomersPageConnection;
}

interface Props extends PageProps {
  data: Data;
  pageContext: any;
}

const Customers = (props: Props) => {
  const { region } = useStore();
  const [customerLogos, setCustomerLogos] = useState<SanityCustomerLogos[]>([]);
  const filteredLogos = useCustomerLogosQuery(region.iban);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setCustomerLogos(filteredLogos.logos?.filter(isNotNullOrUndefined) ?? []);
  }, [filteredLogos]);

  useRegionChanged("customers");

  const sanityCustomersPage = props.data.allSanityCustomersPage.nodes[0];
  const alternateRegions = props.data.altRegions.nodes.map(
    alt => alt._rawRegion
  );

  const hasMultipleFeaturedCaseStudies =
    sanityCustomersPage._rawFeaturedCaseStudies &&
    sanityCustomersPage._rawFeaturedCaseStudies.length > 1;

  return (
    <>
      <SEO
        seoData={sanityCustomersPage.seo}
        alternateRegions={alternateRegions}
        slug={props.pageContext.slug}
      />

      <LayOut>
        <Hero heroContent={sanityCustomersPage.hero} featuredHero />
        <Container
          margin="100px auto 50px auto"
          mobileMargin="50px auto 50px auto"
          backgroundColor="white"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <GridList
            list={sanityCustomersPage.serviceList}
            assetLoading="eager"
          />
        </Container>

        <CustomersBlock
          loadMore
          logos={customerLogos}
          headerText={filteredLogos.title}
        />
        <Container maxWidth="1440px" margin="auto">
          {hasMultipleFeaturedCaseStudies ? (
            <SharedCarousel>
              {sanityCustomersPage.featuredCaseStudies?.map(caseStudy => (
                <FeaturedBlog
                  key={caseStudy?._key}
                  featuredBlogPost={caseStudy}
                  isCaseStudy
                  height="500px"
                  mobileHeight="400px"
                />
              ))}
            </SharedCarousel>
          ) : (
            <FeaturedBlog
              featuredBlogPost={
                sanityCustomersPage?.featuredCaseStudies &&
                sanityCustomersPage?.featuredCaseStudies[0]
              }
              isCaseStudy
            />
          )}
        </Container>

        <TestimonialsSlider
          customMargin="120px auto 170px auto"
          customMobileMargin="50px auto 100px auto"
        />
      </LayOut>
    </>
  );
};

export default Customers;

export const query = graphql`
  query customersQuery($iban: String) {
    allSanityCustomersPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        featuredCaseStudies {
          _id
          _key
          _type
          ...sanityCaseStudy
        }
        _rawFeaturedCaseStudies(resolveReferences: { maxDepth: 5 })
        serviceList {
          title
          description
          Button {
            isInternal
            linkText
            linkUrl
          }
          gridList {
            _key
            description
            image {
              ...sanityImageWithMeta
            }
            link {
              internallink
              linktext
              url
              linkStyle
            }
            title
          }
        }
      }
    }
    altRegions: allSanityCustomersPage {
      nodes {
        _rawRegion(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;
