import { colors, icons, MAX_WIDTH, TABLET_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";
import { Carousel } from "antd";
import { CarouselProps, CarouselRef } from "antd/lib/carousel";
import type * as CSS from "csstype";
import React, { useRef, useState } from "react";
import styled from "styled-components";

const StyledCarousel = styled(Carousel)<{
  height?: CSS.StandardLonghandProperties["height"];
}>`
  ${({ height }) => (height ? `height: ${height};` : "")};

  .slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 1rem 0;
    bottom: -40px;

    list-style-type: none;

    li {
      margin: 0 0.25rem;

      button {
        display: block;
        width: 1rem;
        height: 1rem;
        padding: 0;

        border: none;
        border-radius: 100%;

        text-indent: -9999px;

        border: 2px solid ${colors.lightBlue};
        border-radius: 12px;
        background-color: ${colors.lightBlue};
      }
    }

    li.slick-active button {
    }
  }
`;

const Arrow = styled.img<{
  right?: boolean;
  hide?: boolean;
}>`
  // filter: brightness(0.5);
  width: 50px;
  height: 50px;
  display: block;
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ right }) =>
    right
      ? "right: 10px; transform: rotate(0deg); "
      : "left: 10px; transform: rotate(180deg);"};
  ${({ hide }) => (hide ? "display: none;" : "left: block")};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 10px;
  }
`;

interface Props {
  children?: any;
  customMargin?: string;
  customMobileMargin?: string;
  height?: CSS.StandardLonghandProperties["height"];
}

const SharedCarousel = (props: Props) => {
  const { customMargin, customMobileMargin, children } = props;

  const [hover, setHover] = useState(false);

  const carouselSettings: CarouselProps = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    draggable: true,
    adaptiveHeight: true,
    lazyLoad: "ondemand",
  };
  const carouselSlider = useRef<CarouselRef>(null);

  return (
    <Container
      height="100%"
      width="100%"
      tabletWidth="100%"
      margin={customMargin ? customMargin : "100px auto"}
      mobileMargin={
        customMobileMargin ? customMobileMargin : "50px auto 0px auto"
      }
      position="relative"
      maxWidth={`${MAX_WIDTH}px`}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <Container
        position="absolute"
        width="80%"
        height="100%"
        top="0px"
        left="10%"
      >
        <Arrow
          src={icons.carousalRight}
          alt="Carousel Right"
          onClick={() => carouselSlider?.current?.prev()}
          hide={!hover}
          width={50}
          height={50}
        />
        <Arrow
          right
          src={icons.carousalRight}
          alt="Carousel Left"
          onClick={() => carouselSlider?.current?.next()}
          hide={!hover}
          width={50}
          height={50}
        />
      </Container>
      <StyledCarousel
        {...carouselSettings}
        ref={carouselSlider}
        height={props.height}
      >
        {children}
      </StyledCarousel>
    </Container>
  );
};

export default SharedCarousel;
