import Image from "@components/shared/sub/image";
import { Maybe, SanityGridList } from "@graphql-types";
import { colors } from "@util/constants";
import React from "react";
import { FaFutbol } from "react-icons/fa";
import { Card } from "./Card";
import { CardGrid } from "./CardGrid";
import { Section } from "./FeatureImageList";

interface GridListProps {
  list: Maybe<SanityGridList> | undefined;
  assetLoading?: "eager" | "lazy";
}

const GridList = ({ list, assetLoading }: GridListProps) => {
  if (!list) throw new Error("List does not exist");
  const items = list.gridList ?? [];

  return (
    <Section>
      <h2 style={{ textAlign: "center" }}>{list.title}</h2>
      <p style={{ textAlign: "center" }}>{list.description}</p>
      <CardGrid>
        {items.map(item => (
          <Card
            key={item?._key}
            title={item?.title ?? ""}
            description={item?.description ?? ""}
            image={
              item?.image ? (
                <div>
                  <Image
                    data={item.image}
                    height="50px"
                    width="50px"
                    loading={assetLoading}
                  />
                </div>
              ) : (
                <FaFutbol
                  style={{
                    minWidth: "50px",
                    minHeight: "50px",
                    color: colors.lightBlue,
                  }}
                />
              )
            }
            button={item?.link}
            showBoxShadow={true}
          />
        ))}
      </CardGrid>
    </Section>
  );
};

export { GridList };
export type { GridListProps };
