import {
  MAX_WIDTH,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { Container, MenuButtonGatsbyLink, StyledLink } from "@util/standard";
import React, { useState } from "react";
import styled from "styled-components";
import Image from "@components/shared/sub/image";
import { Maybe } from "@graphql-types";

interface Props {
  loadMore?: boolean;
  logos?: any;
  headerText?: Maybe<string> | undefined;
}

const Wrapper = styled.div<{ bordered?: boolean }>`
  width: 90%;
  max-width: 1000px;
  margin: 50px auto;
  ${({ bordered }) =>
    bordered &&
    `box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  max-width: ${MAX_WIDTH}px;`};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 50px auto;
  }
`;

const Header = styled.h2`
  text-align: center;
  padding-top: 50px;
  padding-bottom: 10px;
  margin: 0px;
  color: black;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-bottom: 10px;
    padding-top: 20px;
    width: 70%;
    margin: 10px auto;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 25px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
  }
`;

const ImageWrapper = styled.div`
  width: 150px;
  height: 180px;
  .gatsby-image-wrapper {
    height: 100%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100px;
    height: 110px;
  }
`;

const ButtonContainer = styled.div<{ loadMore?: boolean }>`
  ${({ loadMore }) =>
    loadMore ? "padding: 0px;" : "padding: 10px 0px 69px 0px;"};

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-bottom: 36px;
  }
`;

const CustomMenuButton = styled(MenuButtonGatsbyLink)`
  font-family: "header bold";
  width: 181px;
  border-radius: 30px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 12px;
    width: 139px;
  }
`;

function CustomersBlock(props: Props) {
  const { loadMore, logos, headerText } = props;
  const [shownNumber, setShownNumber] = useState(10);

  if (!logos) return null;

  return (
    <Wrapper bordered={!loadMore}>
      <Header>{headerText ? headerText : `Loved by healthcare teams`}</Header>
      <StyledContainer>
        {logos &&
          !loadMore &&
          logos.slice(0, 5).map(customer => (
            <Container margin="auto" key={customer._key}>
              <ImageWrapper>
                <Image data={customer.images} height="100%" width="100%" />
              </ImageWrapper>
            </Container>
          ))}

        {logos &&
          loadMore &&
          logos.slice(0, shownNumber).map(customer => (
            <Container margin="auto" key={customer._key}>
              <ImageWrapper>
                <Image data={customer.images} height="100%" width="100%" />
              </ImageWrapper>
            </Container>
          ))}
      </StyledContainer>
      <ButtonContainer loadMore={loadMore}>
        {loadMore ? (
          <>
            {shownNumber < logos.length ? (
              <StyledLink
                onClick={() => {
                  setShownNumber(pre => pre + 10);
                }}
                textAlign="center"
              >
                View More
              </StyledLink>
            ) : null}
          </>
        ) : (
          <CustomMenuButton color="secondary" href="/customers">
            Our customers
          </CustomMenuButton>
        )}
      </ButtonContainer>
    </Wrapper>
  );
}

export default CustomersBlock;
