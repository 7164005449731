import { MAX_WIDTH } from "@util/constants";
import styled from "styled-components";

export const Paper = styled("div")<{
  isBlue?: boolean;
  showBoxShadow?: boolean;
}>`
  background-color: "#fff";
  width: 80%;
  padding: 20px;
  position: relative;
  box-shadow: ${({ showBoxShadow }) =>
    showBoxShadow ? "0 2px 10px 0 rgba(0, 0, 0, 0.3)" : ""};
  max-width: ${MAX_WIDTH}px;
`;
